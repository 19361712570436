<template>
  <div class="default-layouts" style="background-color: #e1e5ed; min-width: 1400px">
    <Nav />

    <slot name="banner">
      <Banner />
    </slot>

    <div class="common-container">
      <slot />

      <div v-if="dialogVisible">
        <ItemInspect :item="detailData.item" :list="detailData.list" :dialog-visible="dialogVisible" @close="dialogVisible = false" />
      </div>
    </div>

    <div v-if="hideAgreement !== 1">
      <Agreement @agree="agree" />
    </div>
    <div class="layout-bg"></div>
    <Footer />
    <ClientOnly>
      <div class="gx-sidebar relative h-325 w-58 rounded-l-[10px] bg-white py-15" :class="sideNaviOpen ?? 'open'">
        <div v-if="sideNaviOpen === 'open'">
          <el-backtop :bottom="130" :right="0" style="width: 58px">
            <div class="flex items-center justify-center" style="width: 58px; height: 58px; background: #ffffff; border-radius: 10px 0px 0px 10px">
              <i class="iconfont icon-zhiding1 text-color-lightgray text-16"></i>
            </div>
          </el-backtop>
        </div>

        <client-only>
          <!--群聊-->
          <el-popover placement="left-start" :width="210" popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 16px;">
            <template #reference>
              <div class="group mb-15 h-47">
                <div class="sidebar-item flex items-center justify-center">
                  <i class="iconfont icon-qunliao text-color-lightgray text-25 group-hover:text-blue"></i>
                </div>
                <div class="text-center text-gray group-hover:text-blue">{{ $t('群聊') }}</div>
              </div>
            </template>

            <template #default>
              <div class="flex items-center">
                <img class="h-45 w-45" src="https://cdn.ok-skins.com/steam-stock/common/c2c8ea0d-45d8-47df-a528-8665d48d5c53.webp" alt="" />
                <div class="ml-10 text-black">
                  <div class="text-14">{{ $t('SteamDT交流群') }}</div>
                  <div class="mt-5 flex items-center">
                    <span class="mr-5 text-12 leading-none">{{ $t('群号：884816621') }}</span>
                    <i class="iconfont icon-fuzhi1 cursor-pointer text-16 leading-none text-gray-light" @click.stop="copyText(884816621)"></i>
                  </div>
                </div>
              </div>

              <div class="mt-10">
                <img src="~/assets/images/common/qrcode.webp" alt="" />
              </div>
            </template>
          </el-popover>
        </client-only>
        <!--   自媒体   -->
        <client-only>
          <el-popover placement="left-start" :width="210" popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 16px;">
            <template #reference>
              <div class="group mb-15 h-47">
                <div class="sidebar-item flex items-center justify-center">
                  <i class="iconfont icon-zimeiti text-color-lightgray text-25 group-hover:text-blue"></i>
                </div>
                <div class="text-center text-gray group-hover:text-blue">{{ $t('自媒体') }}</div>
              </div>
            </template>

            <template #default>
              <div class="flex items-center justify-between">
                <div class="w-80">
                  <div class="flex items-center justify-center">
                    <img class="h-15 w-15" src="https://cdn.ok-skins.com/steam-stock/common/douyin.webp" alt="" />
                    <span>{{ $t('关注抖音') }}</span>
                  </div>
                  <div>
                    <img class="rounded-full bg-black p-2" src="https://cdn.ok-skins.com/steam-stock/common/douyin-qrcode.png" alt="" />
                  </div>
                </div>
                <div class="w-80">
                  <div class="flex items-center justify-center">
                    <img class="h-15 w-15" src="https://cdn.ok-skins.com/steam-stock/common/bilibili.webp" alt="" />
                    <span>{{ $t('关注B站') }}</span>
                  </div>
                  <div>
                    <img src="https://cdn.ok-skins.com/steam-stock/common/bilibili-qrcode.png" alt="" />
                  </div>
                </div>
              </div>
            </template>
          </el-popover>
        </client-only>
        <!--   帮助   -->
        <client-only>
          <el-popover placement="left-start" :width="240" popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 10px 0;min-width: auto;">
            <template #reference>
              <div class="group mb-15 h-47">
                <div class="sidebar-item flex items-center justify-center">
                  <i class="iconfont icon-bangzhu text-color-lightgray text-25 group-hover:text-blue"></i>
                </div>
                <div class="text-center text-gray group-hover:text-blue">{{ $t('帮助') }}</div>
              </div>
            </template>
            <template #default>
              <div class="w-220 px-10">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <img class="mr-5 h-15 w-15" src="https://cdn.ok-skins.com/steam-stock/common/api_doc.webp" alt="" />
                    {{ $t('官方帮助文档') }}
                  </div>
                  <a href="https://www.yuque.com/zilonggege-h3als/kuwu70" target="_blank" class="border-text-blue h-21 rounded-[10px] border px-13 text-blue">{{ $t('前往') }}</a>
                </div>
              </div>
            </template>
          </el-popover>
        </client-only>
        <!--   反馈   -->
        <client-only>
          <el-popover placement="left-start" :width="260" popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 16px;">
            <template #reference>
              <div class="group mb-15 h-47">
                <div class="sidebar-item flex items-center justify-center">
                  <i class="iconfont icon-fankui text-color-lightgray text-25 group-hover:text-blue"></i>
                </div>
                <div class="text-center text-gray group-hover:text-blue">{{ $t('反馈') }}</div>
              </div>
            </template>

            <template #default>
              <div class="w-220">
                <div class="mb-5 flex justify-between text-12 text-black">
                  <img class="h-15 w-15" src="https://cdn.ok-skins.com/steam-stock/common/mail.webp" alt="" />
                  <div>{{ $t('官方邮箱') }} : feedback@steamdt.com</div>
                </div>
                <div class="text-12 text-gray">{{ $t('欢迎使用邮箱与我们联系，我们会在12个小时内回复你的消息') }}</div>
              </div>
            </template>
          </el-popover>
        </client-only>
        <!--  开放平台    -->
        <client-only>
          <el-popover placement="left-start" :width="220" popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 16px;">
            <template #reference>
              <div class="group mb-15 h-47">
                <div class="sidebar-item flex items-center justify-center">
                  <i class="iconfont icon-kaifangpingtai text-color-lightgray text-25 group-hover:text-blue"></i>
                </div>
                <div class="text-center text-gray group-hover:text-blue">{{ $t('开放平台') }}</div>
              </div>
            </template>

            <template #default>
              <div class="w-180">
                <div class="mb-7 flex items-center justify-between">
                  <div class="flex items-center">
                    <img class="mr-5 h-15 w-15" src="https://cdn.ok-skins.com/steam-stock/common/apikey.webp" alt="" />
                    API_KEY
                  </div>
                  <a :href="localePath('/my/setting?type=api')" target="_blank" class="border-text-blue h-21 rounded-[10px] border px-13 text-blue">{{ $t('获取') }}</a>
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <img class="mr-5 h-15 w-15" src="https://cdn.ok-skins.com/steam-stock/common/api_doc.webp" alt="" />
                    API{{ $t('文档') }}
                  </div>
                  <a href="https://doc.steamdt.com/" target="_blank" class="border-text-blue h-21 rounded-[10px] border px-13 text-blue">{{ $t('前往') }}</a>
                </div>
              </div>
            </template>
          </el-popover>
        </client-only>
        <img
          v-if="sideNaviOpen === 'open'"
          src="https://cdn.ok-skins.com/steam-stock/common/side-nav-close.webp"
          class="absolute left-[-22px] top-1/2"
          alt=""
          @click="sideNaviOpen = sideNaviOpen === 'open' ? 'close' : 'open'"
        />
        <img
          v-else
          src="https://cdn.ok-skins.com/steam-stock/common/side-nav-open.webp"
          class="absolute left-[-22px] top-1/2"
          alt=""
          @click="sideNaviOpen = sideNaviOpen === 'open' ? 'close' : 'open'"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import Nav from '~/components/layout/nav.vue'
import Banner from '~/components/layout/banner.vue'
import Agreement from '~/components/layout/agreement.vue'
import Footer from '~/components/layout/footer.vue'
import ItemInspect from '~/components/common/itemInspect.vue'
import { copy } from '#imports'
import { getFingerPrint, getshareReward, getUserInfo } from '~/api/steam'

const { t } = useI18n()
const $t = t
const route = useRoute()
const routeName = route.name
const store = useNuxtStore()
const token = useCookie('SDT_AccessToken')
const mySteamToken = useCookie('SDT_MySteamToken', {
  maxAge: 7 * 24 * 60 * 60 * 1000
})
const sideNaviOpenStorage = useLocalStorage('SDT_SideNaviOpen')
const dialogVisible = ref(false)
const mobileDialog = ref(false)
const sideNaviOpen = ref(sideNaviOpenStorage.value || 'open')
const detailData = reactive({
  list: [],
  item: {}
})
const hideAgreement = useCookie('SDT_HideAgreement', {
  maxAge: 999999999
})

watch(sideNaviOpen, () => {
  sideNaviOpenStorage.value = sideNaviOpen.value
})

onMounted(async () => {
  // document.title = "后台管理";
  // console.log(route);
  sideNaviOpen.value = sideNaviOpenStorage.value || 'open'
  if (route.query?.invite) {
    getshareReward({ shareCode: route.query?.invite })
  }

  if (route.query?.hash) {
    getFingerPrint({
      id: route.query?.hash
    }).then(res => {
      detailData.item = res.data
      dialogVisible.value = true
    })
  }
  // console.log('mySteamToken.value', mySteamToken.value)
  if (!store.userInfo?.id && token.value) {
    const userInfo = await getUserInfo()
    store.setUserInfo(userInfo.data)
    console.log('userInfo', userInfo)
    mySteamToken.value = userInfo.data?.steamId || userInfo.data?.authSteamId
  } else {
    mySteamToken.value = store.userInfo?.steamId || store.userInfo?.authSteamId
  }

  const bindEmailTips = useCookie('SDT_BindEmailTips')
  if (bindEmailTips.value == 1) {
    bindEmailTips.value = 2
    ElMessageBox.confirm($t('该STEAM账号未绑定邮箱，无法通过邮箱登录'), $t('登录成功'), {
      confirmButtonText: $t('立即前往'),
      cancelButtonText: $t('稍后绑定'),
      type: 'warning'
    })
      .then(() => {
        window.location.href = '/my/setting'
      })
      .catch(() => {})
  }
  // const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent)
  // const cookie = useCookie('SDT_MobileDialog')
  // if (isMobile && !cookie.value) {
  //   alert($t('因STEAMDT展示数据全面，为了您的使用体验，请使用手机横屏浏览'))
  //   cookie.value = true
  // }
})

const agree = () => {
  hideAgreement.value = 1
}

// 复制
const copyText = val => {
  copy(val)
  myMessage({
    message: '复制成功'
  })
}
</script>

<style lang="scss">
.default-layouts {
  overflow: hidden;
  .layout-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1000px;
    z-index: 0;
    background: linear-gradient(180deg, #acc6ff 0%, #e1e5ed 100%);
    border-radius: 8px;
  }
}
.common-container {
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
  z-index: 2;
  min-height: 1000px;
}

.gx-sidebar {
  position: fixed;
  //right: 0px;
  bottom: 200px;
  z-index: 10;
  &.open {
    right: 0;
  }
  &.close {
    right: -60px;
  }
  .el-backtop {
    background: $white-color;

    &:hover i {
      color: $primary-color;
    }
  }

  .sidebar-item {
    width: 58px;
    //height: 58px;
    //background: $white-color;
    //box-shadow: var(--el-box-shadow-lighter);
    //border-radius: 50%;
    //margin-bottom: 10px;
    cursor: pointer;
  }
}
</style>
